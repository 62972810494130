import React from 'react';
import Img from 'gatsby-image';
import GalleryModal from './GalleryModal';
import {Link} from 'gatsby';
import {MDBBtn} from 'mdbreact';

class Gallery extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showModal: false
        }
        this.modalRef = React.createRef();

        this.toggleModal = this.toggleModal.bind(this);
        this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
        this.handleModalClick = this.handleModalClick.bind(this);
        this.handleModalScroll = this.handleModalScroll.bind(this);
        this.handleModalKeyDown = this.handleModalKeyDown.bind(this);
    }

    handleThumbnailClick(event){
        event.preventDefault();
        const link = event.currentTarget.hash;
        const element = document.getElementById(link.substr(1));
        element.scrollIntoView({'inline':'center'});
        this.toggleModal();

    }

    handleModalClick(event){
        event.preventDefault();
        this.toggleModal();
    }

    handleModalKeyDown(event){
        event.preventDefault();
        event.persist();
        console.log(event);
        if(event.key === 'Escape'){
            this.setState({showModal:false});
        }
    }
    
    handleModalScroll(event){
        event.preventDefault();
        let delta = Math.max(-1, Math.min(1, (event.deltaY || -event.detail)));
        event.currentTarget.scrollLeft -= (delta * 40);
    }
    
    toggleModal(){
        this.setState({showModal: !this.state.showModal})
    }

    render(){
        return(
            <>
            <div className='gallery-paper z-depth-2'>
            <ul className='mdc-image-list mdc-image-list--masonry my-masonry-image-list'>
                {this.props.thumbnails.map(((image,index) => {
                    return(
                        <li key={index} className='mdc-image-list__item'>
                            <a href={`#${index}`} onClick={this.handleThumbnailClick}>
                                <Img 
                                    className='galleryThumb z-depth-2' 
                                    alt={`gallery thumbnail ${index}`}
                                    fluid={image.node.childImageSharp.fluid}/>
                            </a>
                        </li>
                    )
                }))
                }
            </ul>
            <div className='text-center'>
                <Link to={this.props.btnHref}>
                    <MDBBtn 
                        // outline 
                        className='pink accent-1' 
                        // gradient='peach'
                        size='lg'>{this.props.btnText}</MDBBtn>
                </Link>

            </div>
            </div>
            <GalleryModal 
                isVisible={this.state.showModal} 
                onClick={this.handleModalClick}
                onScroll={this.handleModalScroll}
                onKeyDown={this.handleModalKeyDown}
                images={this.props.modalPhotos}
                modalRef={this.modalRef}/>
            </>
        )
    }
}

export default Gallery