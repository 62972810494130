import React from 'react';

class GalleryModal extends React.Component{
    constructor(props){
        super(props);
    }
    componentDidUpdate(){
        this.props.modalRef.current.focus();
    }

    render(){
        return(
            <>
            <div id='modal' className={this.props.isVisible?'visible':'invisible'} 
                ref={this.props.modalRef}
                style={{position:'fixed', top:'0',bottom:'0',right:'0',left:'0',backgroundColor:'rgba(1,1,1,.8)',zIndex:9999,height:'100vh'}}
                onClick={this.props.onClick}
                onKeyUp={this.props.onKeyDown}
                role='button'
                tabIndex='-1'>
                <div style={{display:'flex', overflowX:'scroll',height:'100vh',width:'100%'}}
                    onWheel={this.props.onScroll}>
                        
                    {this.props.images.map(((image,index) => {
                        return(
                            <div key={index} id={index} tabIndex={index} 
                                style={{padding:'20px', marginBottom:'auto', marginTop:'auto'}}>
                                <picture>
                                    <source type='image/webp' 
                                        sizes={image.node.childImageSharp.fluid.sizes}
                                        srcSet={image.node.childImageSharp.fluid.srcSetWebp}/>
                                    <source 
                                        sizes={image.node.childImageSharp.fluid.sizes}
                                        srcSet={image.node.childImageSharp.fluid.srcSet}/>
                                    
                                    <img sizes={image.node.childImageSharp.fluid.sizes} 
                                        srcSet={image.node.childImageSharp.fluid.srcSet}
                                        src={image.node.childImageSharp.fluid.src}
                                        loading='lazy' alt={`modal-${index}`} style={{maxHeight:'90vh', maxWidth:'85vw',transition:'opacity 500s ease 0s'}}/>
                                </picture>
                            </div>
                        )
                        }))
                    }
                </div>
            </div>
            </>
        )

    }
}

export default GalleryModal